<div class="modal-container">
    <div class="header border-bottom">
        <h4 mat-dialog-title>
            <span *ngIf="!data.isEdit">Create</span>
            <span *ngIf="data.isEdit">Update</span>
            a Tech Testimonial
        </h4>
        <mat-icon [mat-dialog-close]="false" class="close-icon ">close</mat-icon>
    </div>
    <div class="body">
        <mat-dialog-content class="mat-dialog">
            <div class="row p-3">
                <div class="col-md-12">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-12 col-md-4 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Description</mat-label>
                                    <input matInput formControlName="description" type="text" />
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['description'].errors && formControl['description'].errors['required'])">
                                        Description is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Youtube URL</mat-label>
                                    <input matInput formControlName="youtube_url" type="text" />
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['youtube_url'].errors && formControl['youtube_url'].errors['required'])">
                                        Youtube URL is required!
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['youtube_url'].errors && formControl['youtube_url'].errors['pattern'])">
                                        URL is not valid!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="country">
                                        <mat-option value="national">National</mat-option>
                                        <mat-option value="global">Global</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['country'].errors && formControl['country'].errors['required'])">
                                        Country is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Category</mat-label>
                                    <mat-select formControlName="category_id">
                                        <mat-option [value]="category.id" *ngFor="let category of metaData.categories">{{category.name}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['category_id'].errors && formControl['category_id'].errors['required'])">
                                        Category is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Product</mat-label>
                                    <mat-select formControlName="product_id" (selectionChange)="onProductSelected($event.value)">
                                        <mat-option [value]="product.id" *ngFor="let product of metaData.products">{{product.name}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['product_id'].errors && formControl['product_id'].errors['required'])">
                                        Product is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 form-group" *ngIf="form.controls['product_id'].value">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Product Variant</mat-label>
                                    <mat-select formControlName="product_variant_id">
                                        <mat-option [value]="variant.id" *ngFor="let variant of selectedProduct?.product_variants">{{variant.name}} - {{variant.code}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Published Date</mat-label>
                                    <input matInput formControlName="published_at" [matDatepicker]="picker"
                                        type="text" />
                                    <mat-hint>mm/dd/yyyy</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && (formControl['published_at'].errors && formControl['published_at'].errors['required'])">
                                        Published Date is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>



                            <div class="col-12 col-md-4 form-group" *ngIf="data.isEdit">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Priority</mat-label>
                                    <input matInput formControlName="priority" type="text" />
                                </mat-form-field>
                            </div>

                            <div class="col-12 form-group my-3">
                                <mat-slide-toggle formControlName="is_enabled" class="d-block example-margin">
                                    Enable/Disable
                                    the
                                    Tech Testimonial</mat-slide-toggle>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="footer">
        <button class="ml-3 mb-3" style="color: black !important;" mat-raised-button (click)="dialogRef.close()">
            Cancel
        </button>
        <button class="btn btn-info ml-3 mb-3" *ngIf="!data.isEdit" (click)="createTechTestimonial()">
            Create
        </button>
        <button class="btn btn-info ml-3 mb-3" *ngIf="data.isEdit" (click)="updateTechTestimonials()">
            Update
        </button>
    </div>
</div>