<section>
    <div class="card">
        <div class="card-header card-box-shadow bt-4 d-flex justify-content-between p-3">
            <h4 class="card-title m-0 float-left font-weight-bold">
                Tech Testimonials
            </h4>
            <div class="d-flex justify-content-end">
                <div>
                    <mat-icon matTooltip="Add a Testimonial" (click)="openSubscriptionModal()"
                        class="pr-3 card-header-icon pointer" matTooltipClass="custom-tooltip">
                        add
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="w-100 table-responsive">
                <table class="w-100" mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.description || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="youtube_url">
                        <th mat-header-cell *matHeaderCellDef>Youtube URL</th>
                        <td mat-cell *matCellDef="let element">
                            <a *ngIf="element?.youtube_url" [href]="element?.youtube_url"
                                target="_blank">{{element?.youtube_url}}</a>
                            <div *ngIf="!element?.youtube_url">N/A</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let element"> {{element?.category.name || 'N/A'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Product">
                        <th mat-header-cell *matHeaderCellDef>Product</th>
                        <td mat-cell *matCellDef="let element"> {{element?.product.name || 'N/A'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Product Variant">
                        <th mat-header-cell *matHeaderCellDef>Product Variant</th>
                        <td mat-cell *matCellDef="let element"> {{element?.product_variant?.name || 'N/A'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="priority">
                        <th mat-header-cell *matHeaderCellDef>Priority</th>
                        <td mat-cell *matCellDef="let element"> {{element?.priority || 'N/A'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="is_enabled">
                        <th mat-header-cell *matHeaderCellDef>Enable / Disable </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle style="max-width: max-content;margin: auto;"
                                [(ngModel)]="element.is_enabled" class="d-block example-margin"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="pointer" matTooltip="Edit Tech Testimonial" (click)="openSubscriptionModal(element,true)" matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square"></i>
                            </a>
                            <a class="pointer ml-3" (click)="deleteItem(element)" [matTooltip]="'Delete File'"
                                matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #c41414;" class="fas fa-2x fa-trash-alt"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <!-- <div class="text-center py-2 no-record" *ngIf="!dataSource?.data.length">
                No record found</div> -->
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator> -->
        </div>
    </div>
</section>