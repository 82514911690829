<div class="modal-container">
    <div class="header border-bottom">
        <h4 mat-dialog-title>Create an Offers / Promotions</h4>
        <mat-icon [mat-dialog-close]="false" class="close-icon ">close</mat-icon>
    </div>
    <div class="body">
        <mat-dialog-content class="mat-dialog">
            <div class="row p-3">
                <div class="col-md-12">
                    <form [formGroup]="form">
                        <div class="row">

                            <div class="col-12 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Start Date</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="start_date" type="text" />
                                    <mat-hint>mm/dd/yyyy</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['start_date'].errors && formControl['start_date'].errors['required'])">
                                        Start Date is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <div class="col-md-12 mt-4">
                                    <h3 class="section-heading section-heading-not-first">
                                        Thumb Image
                                    </h3>
                                </div>

                                <div class="row col-md-12">
                                    <div class="col-md-12 mb-3" *ngIf="thumbImage">
                                        <img class="logo-btn" [src]="thumbImage" width="100" height="100" />
                                    </div>
                                    <div class="col-md-12 mb-3" *ngIf="UpdatedThumbImg">
                                        <div class="logo-wrap">
                                            <i (click)="UpdatedThumbImg=null;UpdatedThumbImgFile=null"
                                                class="fa fa-times fa-1x" aria-hidden="true"></i>
                                            <img class="logo-btn" [src]="UpdatedThumbImg" width="100" height="100" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <input class="file-input" id="file-upload" type="file"
                                            accept="image/png, image/jpeg, image/webp" (change)="preview($event)" />
                                        <button mat-raised-button type="button" class="file-upload-btn">
                                            <label for="file-upload">
                                                Browse and upload Image
                                            </label>
                                        </button>
                                    </div>
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['image'].errors && formControl['image'].errors['required'])">
                                        Thumb Image is required!
                                    </mat-hint>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="footer">
        <button class="ml-3 mb-3" style="color: black !important;" mat-raised-button (click)="dialogRef.close()">
            Cancel
        </button>
        <button class="btn btn-info ml-3 mb-3" (click)="createPressCoverage()">
            Create
        </button>
    </div>
</div>