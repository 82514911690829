<div class="modal-container">
    <div class="header border-bottom">
        <h4 mat-dialog-title *ngIf="!data.isEdit">Create a Press Coverage</h4>
        <h4 mat-dialog-title *ngIf="data.isEdit">Update a Press Coverage</h4>
        <mat-icon [mat-dialog-close]="false" class="close-icon ">close</mat-icon>
    </div>
    <div class="body">
        <mat-dialog-content class="mat-dialog">
            <div class="row p-3">
                <div class="col-md-12">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">Title</mat-label>
                                    <input matInput formControlName="title" type="text" />
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['title'].errors && formControl['title'].errors['required'])">
                                        Title is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">Description</mat-label>
                                    <input matInput formControlName="description" type="text" />
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['description'].errors && formControl['description'].errors['required'])">
                                        Description is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">Vendor</mat-label>
                                    <input matInput formControlName="vendor" type="text" />
                                    <!-- <mat-hint
                            *ngIf="formSubmitted && (formControl['vendor'].errors && formControl['vendor'].errors['required'])">
                            Description is required!
                          </mat-hint> -->
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">External URL</mat-label>
                                    <input matInput formControlName="external_url" type="text" />
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['external_url'].errors && formControl['external_url'].errors['required'])">
                                        External URL is required!
                                    </mat-hint>
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['external_url'].errors && formControl['external_url'].errors['pattern'])">
                                        URL is not valid!
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="country">
                                        <mat-option value="national">National</mat-option>
                                        <mat-option value="global">Global</mat-option>
                                    </mat-select>
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['country'].errors && formControl['country'].errors['required'])">
                                        Country is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Is Featured</mat-label>
                                    <input matInput formControlName="is_featured" type="number" value="0" />
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['is_featured'].errors && formControl['is_featured'].errors['required'])">
                                        Is Featured is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Published Date</mat-label>
                                    <input matInput formControlName="published_at" [matDatepicker]="picker"
                                        type="text" />
                                    <mat-hint>mm/dd/yyyy</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['published_at'].errors && formControl['published_at'].errors['required'])">
                                        Published Date is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label class="mat-label-field-padding">Priority</mat-label>
                                    <input matInput formControlName="priority" type="text" />
                                    <mat-hint class="error"
                                        *ngIf="formSubmitted && (formControl['priority'].errors && formControl['priority'].errors['required'])">
                                        Priority Date is required!
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-12 form-group my-3">
                                <mat-slide-toggle formControlName="is_enabled" class="d-block example-margin">
                                    Enable/Disable
                                    the
                                    Press Coverage</mat-slide-toggle>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class=" mt-4">
                                        <h3 class="section-heading section-heading-not-first">
                                            Desktop Image
                                        </h3>
                                    </div>

                                    <div class="row ">
                                        <!-- <div class=" mb-3" *ngIf="desktopthumbImage">
                                            <img class="logo-btn" [src]="desktopthumbImage" width="100" height="100" />
                                        </div> -->
                                        <div class=" mb-3" *ngIf="desktopUpdatedThumbImg">
                                            <div class="logo-wrap">
                                                <i (click)="desktopUpdatedThumbImg=null;desktopImgFile=null"
                                                    class="fa fa-times fa-1x" aria-hidden="true"></i>
                                                <img class="logo-btn" [src]="desktopUpdatedThumbImg" width="100"
                                                    height="100" />
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <input class="file-input" id="file-upload" type="file"
                                                accept="image/png, image/jpeg, image/webp"
                                                (change)="preview($event, true)" />
                                            <button mat-raised-button type="button" class="file-upload-btn">
                                                <label for="file-upload">
                                                    Browse and upload Desktop Image
                                                </label>
                                            </button>
                                            <mat-hint class="error"
                                                *ngIf="formSubmitted && (formControl['desktop_image'].errors && formControl['desktop_image'].errors['required'])">
                                                Desktop image is required!
                                            </mat-hint>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class=" mt-4">
                                        <h3 class="section-heading section-heading-not-first">
                                            Mobile Image
                                        </h3>
                                    </div>

                                    <div class="row ">
                                        <!-- <div class=" mb-3" *ngIf="mobilethumbImage">
                                            <img class="logo-btn" [src]="mobilethumbImage" width="100" height="100" />
                                        </div> -->
                                        <div class=" mb-3" *ngIf="mobileUpdatedThumbImg">
                                            <div class="logo-wrap">
                                                <i (click)="mobileUpdatedThumbImg=null;mobileImgFile=null"
                                                    class="fa fa-times fa-1x" aria-hidden="true"></i>
                                                <img class="logo-btn" [src]="mobileUpdatedThumbImg" width="100"
                                                    height="100" />
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <input class="file-input" id="mobile-file-upload" type="file"
                                                accept="image/png, image/jpeg, image/webp"
                                                (change)="preview($event, false)" />
                                            <button mat-raised-button type="button" class="file-upload-btn">
                                                <label for="mobile-file-upload">
                                                    Browse and upload Mobile Image
                                                </label>
                                            </button>
                                            <mat-hint class="error"
                                                *ngIf="formSubmitted && (formControl['mobile_image'].errors && formControl['mobile_image'].errors['required'])">
                                                Mobile image is required!
                                            </mat-hint>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="footer">
        <button class="ml-3 mb-3" style="color: black !important;" mat-raised-button (click)="dialogRef.close()">
            Cancel
        </button>
        <button *ngIf="!data.isEdit" class="btn btn-info ml-3 mb-3" (click)="createPressCoverage()">
            Create
        </button>
        <button *ngIf="data.isEdit" class="btn btn-info ml-3 mb-3" (click)="updatePressCoverage()">
            Update
        </button>
    </div>
</div>