<section class="pb-1">
    <div class="card">
      <div class="card-header card-box-shadow bt-4">
        <h4 class="card-title m-0 font-weight-bold">Product Group List</h4>
      </div>
      <div class="card-body">
        
        <div class="w-100 table-responsive">
            <table mat-table [dataSource]="dataSource">
          
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
          
              <!-- code Column -->
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Code </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
              </ng-container>
          
              <!-- Product Code Column -->
              <ng-container matColumnDef="Product Group Type">
                <th mat-header-cell *matHeaderCellDef> Product Group Code </th>
                <td mat-cell *matCellDef="let element"> {{element.product_type.name}} </td>
              </ng-container>

              <ng-container matColumnDef="View on website">
                <th mat-header-cell *matHeaderCellDef> View on website </th>
                <td mat-cell *matCellDef="let element">
                  <a target="_blank"
                    [href]="environment.websiteUrl + element.relative_url">{{environment.websiteUrl}}{{element.relative_url}}</a>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <a matTooltip="Edit Product Group" matTooltipClass="custom-tooltip"
                        [routerLink]="['/update/product-group',element.id]">
                        <i style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square"></i>
                    </a>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator [length]="totalPages" [pageSizeOptions]="[50, 100, 200]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator>
          </div>
          

        <div class="col-md-12 border-top p-2 button-container d-flex justify-content-between align-items-center">
          <div>
            <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
              <i class='fas fa-arrow-left'></i> Go back
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>