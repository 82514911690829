<section>
    <div class="card">
        <div class="card-header card-box-shadow bt-4 d-flex justify-content-between p-3">
            <h4 class="card-title m-0 float-left font-weight-bold">
                Home Page Slides List
            </h4>
            <div class="d-flex justify-content-end">
                <div>
                    <mat-icon matTooltip="Add a New Home Slide" (click)="openSubscriptionModal()"
                        class="pr-3 card-header-icon pointer" matTooltipClass="custom-tooltip">
                        add
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="w-100 table-responsive">
                <table class="w-100" mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="Link">
                        <th mat-header-cell *matHeaderCellDef> Link </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">Link</mat-label>
                                    <input matInput [(ngModel)]="element.click_link" type="text" />
                                    <mat-hint class="error" *ngIf="!element.click_link">
                                        Redirect Link is required!
                                    </mat-hint>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="desktop image">
                        <th mat-header-cell *matHeaderCellDef>Desktop Image </th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.desktop_image_full_url" width="100" height="100" class="my-3">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="mobile image">
                        <th mat-header-cell *matHeaderCellDef>Mobile Image </th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.mobile_image_full_url" width="100" height="100" class="my-3">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="priority">
                        <th mat-header-cell *matHeaderCellDef>Priority </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field style="max-width: 80px;" appearance="outline">
                                <mat-label>Priority</mat-label>
                                <mat-select [(ngModel)]="element.priority">
                                    <mat-option *ngFor="let priority of priorities" [value]="priority">
                                        {{ priority }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="!element.priority">
                                    Proority is required!
                                </mat-hint>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="is_enabled">
                        <th mat-header-cell *matHeaderCellDef>Enable / Disable </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle style="max-width: max-content;margin: auto;" [(ngModel)]="element.is_enabled" class="d-block example-margin"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="pointer" (click)="deleteItem(element)" [matTooltip]="'Delete File'"
                                matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #c41414;" class="fas fa-2x fa-trash-alt"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="text-center py-2 no-record" *ngIf="!dataSource?.length">
                No record found</div>
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator> -->
            <div
                class="col-md-12 mt-3 border-top p-2 button-container d-flex justify-content-between align-items-center">
                <div>
                    <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
                        <i class='fas fa-arrow-left'></i> Go back
                    </a>
                </div>
                <button mat-raised-button type="submit" (click)="updateHomeSlider()" class="btn btn-info">
                    <span [ngClass]="{'mr-2': isLoading}">
                        <ng-container>Update</ng-container>
                    </span>
                    <i *ngIf="isLoading" class="fas fa-circle-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </div>
</section>