<section class="pb-1">
    <div class="card">
      <div class="card-header card-box-shadow bt-4">
        <h4 class="card-title m-0 font-weight-bold">Contact Request List</h4>
      </div>
      <div class="card-body">
        
        <div class="w-100 table-responsive">
            <table mat-table [dataSource]="dataSource">
          \
              <ng-container matColumnDef="Customer Info">
                <th mat-header-cell *matHeaderCellDef> Customer Info </th>
                <td mat-cell *matCellDef="let element"> 
                    <div>{{element.name}}</div> 
                    <div><a href="mailto:{{element.email}}">{{element.email}}</a></div>
                    <div><a href="tel:{{element.phone}}">{{element.phone}}</a></div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="Subject">
                <th mat-header-cell *matHeaderCellDef> Subject </th>
                <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
              </ng-container>
          
              <ng-container matColumnDef="Body">
                <th mat-header-cell *matHeaderCellDef> Body </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="my-3">{{element.body}}</div>
                 </td>
              </ng-container>

              <ng-container matColumnDef="Created Time">
                <th mat-header-cell *matHeaderCellDef> Created Time </th>
                <td mat-cell *matCellDef="let element"> {{element.local_created_at}} </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator [length]="totalPages" [pageSizeOptions]="[5, 10, 20, 50]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator>
          </div>
          

        <div class="col-md-12 border-top p-2 button-container d-flex justify-content-between align-items-center">
          <div>
            <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
              <i class='fas fa-arrow-left'></i> Go back
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>