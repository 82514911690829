<div class="modal-container">
    <div class="header border-bottom">
        <h4 mat-dialog-title>Add a Home Page Slide</h4>
        <mat-icon [mat-dialog-close]="false" class="close-icon ">close</mat-icon>
    </div>
    <div class="body">
        <mat-dialog-content class="mat-dialog">
            <div class="row p-3">
                <div class="col-12">
                    <form [formGroup]="form">
                        <div class="row">

                            <div class="col-12 form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label class="mat-label-field-padding">Link</mat-label>
                                    <input matInput formControlName="click_link" type="text" />
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['click_link'].errors && formControl['click_link'].errors['required'])">
                                        Redirect Link is required!
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formSubmitted || (formControl['click_link'].errors && formControl['click_link'].errors['pattern'])">
                                        Redirect Link is invalid!
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 form-group my-3">
                                <mat-slide-toggle formControlName="is_enabled" class="d-block example-margin">
                                    Enable/Disable
                                    the Home Page Slide
                                    </mat-slide-toggle>
                            </div>

                            <div class="col-12">
                                <div class="col-md-12 mt-4">
                                    <h3 class="section-heading section-heading-not-first">
                                        Desktop Image
                                    </h3>
                                </div>

                                <div class="row col-12">
                                    <!-- <div class="col-md-12 mb-3" *ngIf="desktopthumbImage">
                                        <img class="logo-btn" [src]="desktopthumbImage" width="100" height="100" />
                                    </div> -->
                                    <div class="col-12 mb-3" *ngIf="UpdatedDesktopThumbImgFile">
                                        <div class="logo-wrap">
                                            <i (click)="desktopthumbImage=null;UpdatedDesktopThumbImgFile=null"
                                                class="fa fa-times fa-1x" aria-hidden="true"></i>
                                            <img class="logo-btn" [src]="desktopthumbImage" width="100" height="100" />
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <input class="file-input" id="file-upload" type="file"
                                            accept="image/png, image/jpeg, image/webp"
                                            (change)="preview($event,'desktop')" />
                                        <button mat-raised-button type="button" class="file-upload-btn">
                                            <label for="file-upload">
                                                Browse and upload Image for Desktop
                                            </label>
                                        </button>
                                        <mat-hint class="error"
                                            *ngIf="formSubmitted && (formControl['desktop_image'].errors && formControl['desktop_image'].errors['required'])">
                                            Desktop image is required!
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="col-12 mt-4">
                                    <h3 class="section-heading section-heading-not-first">
                                        Mobile Image
                                    </h3>
                                </div>

                                <div class="row col-12">
                                    <!-- <div class="col-md-12 mb-3" *ngIf="mobileThumbImage">
                                        <img class="logo-btn" [src]="mobileThumbImage" width="100" height="100" />
                                    </div> -->
                                    <div class="col-12 mb-3" *ngIf="UpdatedMobileThumbImgFile">
                                        <div class="logo-wrap">
                                            <i (click)="mobileThumbImage=null;UpdatedMobileThumbImgFile=null"
                                                class="fa fa-times fa-1x" aria-hidden="true"></i>
                                            <img class="logo-btn" [src]="mobileThumbImage" width="100" height="100" />
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <input class="file-input" id="file-upload-mobile" type="file"
                                            accept="image/png, image/jpeg, image/webp" (change)="preview($event)" />
                                        <button mat-raised-button type="button" class="file-upload-btn">
                                            <label for="file-upload-mobile">
                                                Browse and upload Image for Mobile
                                            </label>
                                        </button>
                                        <mat-hint class="error"
                                            *ngIf="formSubmitted && (formControl['mobile_image'].errors && formControl['mobile_image'].errors['required'])">
                                            Mobile image is required!
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="footer">
        <button class="ml-3 mb-3" style="color: black !important;" mat-raised-button (click)="dialogRef.close()">
            Cancel
        </button>
        <button class="btn btn-info ml-3 mb-3" (click)="createPressCoverage()">
            Create
        </button>
    </div>
</div>