<section class="pb-1">
  <div class="card">
    <div class="card-header card-box-shadow bt-4 d-flex align-items-center">
      <h4 class="card-title m-0 font-weight-bold">Product Group</h4>
      <a style="margin-left: 8px;" target="_blank" *ngIf="relativeUrl"
        [href]="environment.websiteUrl + relativeUrl">(View on Website)</a>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 mt-3">
          <mat-tab-group animationDuration="0ms" #ctdTabset (selectedTabChange)="changeTab($event)"
            [selectedIndex]="selectedIndex">
            <mat-tab [label]="'Details'">

              <div class="row p-3">
                <div class="col-md-12">
                  <form [formGroup]="productVarientForm">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label class="mat-label-field-padding">Product Group Code</mat-label>
                          <input matInput formControlName="code" type="text" />
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['code'].errors && formControl['code'].errors['required'])">
                            Product Group Code is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label class="mat-label-field-padding">Product Name</mat-label>
                          <input matInput formControlName="name" type="text" />
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['name'].errors && formControl['name'].errors['required'])">
                            Product Name is required!
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label>Product Group Type</mat-label>
                          <mat-select formControlName="product_type_id">
                            <mat-option *ngFor="let product of products" [value]="product.id">
                              {{ product.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['product_type_id'].errors && formControl['product_type_id'].errors['required'])">
                            Product Group Type is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label>Category</mat-label>
                          <mat-select formControlName="category_id">
                            <mat-option *ngFor="let category of metaData?.categories" [value]="category.id">
                              {{ category.name }}
                              <ng-container *ngIf="category.parent_category.name">({{category.parent_category.name}})
                              </ng-container>
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['category_id'].errors && formControl['category_id'].errors['required'])">
                            Category is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-12 form-group" *ngIf="componentType == 'update'">
                        <mat-slide-toggle formControlName="is_enabled" class="d-block example-margin">Enable/Disable the
                          product group</mat-slide-toggle>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-tab>

            <mat-tab [label]="'Products'" *ngIf="componentType == 'update'">

              <div class="w-100 table-responsive">
                <table mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="Product">
                    <th mat-header-cell *matHeaderCellDef> Product </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Open on Website">
                    <th mat-header-cell *matHeaderCellDef> Open on Website </th>
                    <td mat-cell *matCellDef="let element; let i = index"> <a
                        [href]="environment.websiteUrl + element.relative_url"
                        target="_blank">{{environment.websiteUrl}}{{element.relative_url}}</a> </td>
                  </ng-container>

                  <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef>Priority </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field style="max-width: 80px;" appearance="outline">
                        <mat-label>Priority</mat-label>
                        <mat-select [(ngModel)]="element.priority">
                          <mat-option *ngFor="let priority of priorities" [value]="priority">
                            {{ priority }}
                          </mat-option>
                        </mat-select>
                        <mat-hint class="error" *ngIf="!element.priority">
                          Proority is required!
                        </mat-hint>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <i [routerLink]="['/update/product-varients',element.id]"
                        style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square pointer"></i>
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div class="text-center my-3" *ngIf="!dataSource?.length">No Records Found!</div>

              </div>

            </mat-tab>
            <mat-tab [label]="'Images'" *ngIf="componentType == 'update'">

              <div class="m-3">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="imagesTabSelectedIndex">

                  <!-- product images tab -->
                  <mat-tab [label]="'Description Images'">
                    <div class="m-3">
                      <h4 style="font-weight: bold;">Current Images</h4>
                    </div>
                    <div class="w-100 table-responsive">
                      <table mat-table [dataSource]="productImagesDataSource">

                        <ng-container matColumnDef="Image">
                          <th mat-header-cell *matHeaderCellDef> Image </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <img class="my-2" [src]="element.full_url" width="100" height="100" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="priority">
                          <th mat-header-cell *matHeaderCellDef>Priority </th>
                          <td mat-cell *matCellDef="let element">
                            <mat-form-field style="max-width: 80px;" appearance="outline">
                              <mat-label>Priority</mat-label>
                              <mat-select [(ngModel)]="element.priority">
                                <mat-option *ngFor="let priority of descriptionImagespriorities" [value]="priority">
                                  {{ priority }}
                                </mat-option>
                              </mat-select>
                              <mat-hint class="error" *ngIf="!element.priority">
                                Proority is required!
                              </mat-hint>
                            </mat-form-field>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="is_enable">
                          <th mat-header-cell *matHeaderCellDef>Enable / Disable </th>
                          <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle style="max-width: max-content;margin: auto;"
                              [(ngModel)]="element.is_enabled" class="d-block example-margin"></mat-slide-toggle>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Actions">
                          <th mat-header-cell *matHeaderCellDef> Actions </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <a class="pointer" (click)="removeFile(element)" [matTooltip]="'Delete File'"
                              matTooltipClass="custom-tooltip">
                              <i style="font-size: 1.6em; color: #c41414;" class="fas fa-2x fa-trash-alt"></i>
                            </a>
                          </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="productImagesDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: productImagesDisplayedColumns;"></tr>
                      </table>
                      <div class="text-center my-3" *ngIf="!productImagesDataSource?.length">No Records Found!
                      </div>
                    </div>
                    <div class="m-3" style="margin-top: 80px !important;">
                      <h4 style="font-weight: bold;">Upload More Images</h4>

                      <div class="d-flex my-3" style="gap: 1rem;">
                        <img [src]="img" *ngFor="let img of myFilesImages" width="100" height="100">
                      </div>

                      <button style="font-size: 12px;" mat-raised-button (click)="browseMultipleFileButtonClicked()"
                        class="btn btn-info">
                        Browse Multiple Files
                      </button>
                      <input multiple #inputMMultipleFiles accept="image/png, image/jpeg, image/webp"
                        (change)="onFileChange($event)" type="file" hidden />
                    </div>

                    <!-- [ngClass]="{'not-allowed': !form.valid}" -->
                    <!-- [disabled]="!form.valid" -->
                    <div class="pt-1 m-3">
                      <button class="btn-info" mat-raised-button mat-button color="primary"
                        [ngClass]="{'spiner':isLoader}" type="submit" style="font-size: 16px;"
                        (click)="uploadMultipleImages()">Upload
                        <i *ngIf="isLoader" class="fas fa-circle-notch fa-spin "></i>
                      </button>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-md-12 mt-4 border-top p-2 button-container d-flex justify-content-between align-items-center">
        <div>
          <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
            <i class='fas fa-arrow-left'></i> Go back
          </a>
        </div>
        <!-- [disabled]="!productVarientForm.valid || isLoading" -->
        <button mat-raised-button type="submit" (click)="saveProductDetailsForm()"
          class="btn btn-info" style="display: flex; gap: 12px;">
          <span [ngClass]="{'mr-2': isLoading}">
            <ng-container *ngIf="componentType == 'create'">Create</ng-container>
            <ng-container *ngIf="componentType == 'update'">Update</ng-container>
          </span>
          <i *ngIf="isLoading" class="fas fa-circle-notch fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</section>